
import React, { useState, useEffect, Fragment } from "react";
import i18n from "../../../i18n";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { useSelector, useDispatch } from 'react-redux';
import getStyles from "./styles";
import { useStyles } from "react-styles-hook";
import Layout from "../../components/Layout";
import NavBarMenu from '../../components/NavBarMenu';
import SnackBar from '../../components/SnackBar';
import CoverImage from '../../components/CoverImage';
import selectionImage from '../../../assets/selectionImage.png';
import ProductListCard from '../../components/ProductListCard';
import PriceFilter from '../../components/PriceFilter';
import DropdownSelector from '../../components/DropdownSelector';
import Loader from '../../components/Loader';
import { Transition } from '@headlessui/react';
import { Pagination } from "../../components/Pagination";
import { filterSelectionPageLaunch } from "./reducer";
import generateMeta from '../../utils/meta';

const SelectionPage = ({ data, pageContext }) => {

  // SEO
  const initMetas = [{
    name: 'og:url',
    content: `${process.env.GATSBY_WEBSITE_URL}${pageContext.currentPath}`,
  }];

  const metas = generateMeta(initMetas, data?.markdownRemark?.frontmatter);

  const today = new Date();

  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const dispatch = useDispatch();

  const sortedFirstLevelCategorySlugs = ["femme", "enfant", "homme", "coaching-capillaire"];
  // i18n used for translation
  i18n(pageContext.locale);

  // for used the style 
  const classes = useStyles(getStyles());

  const searchLocale = pageContext.locale;
  const [searchKey, setSearchKey] = useState(null);
  const [orderType, setOrderType] = useState('desc - weight');
  const [searchPage, setSearchPage] = useState(null);
  const [searchPriceMin, setSearchPriceMin] = useState(null);
  const [searchPriceMax, setSearchPriceMax] = useState(null);

  // reducer
  const { isLoading, productContents, snackBarLoading, snackBarOpen, snackBarMessage, snackBarError, page, lastPage } = useSelector((state) => ({
    isLoading: state.getIn(["selectionPage", "isLoading"]),
    productContents: state.getIn(["selectionPage", "productContents"]),
    errors: state.getIn(["selectionPage", "errors"]),
    snackBarLoading: state.getIn(["pageList", "snackBarLoading"]),
    snackBarOpen: state.getIn(["pageList", "snackBarOpen"]),
    snackBarMessage: state.getIn(["pageList", "snackBarMessage"]),
    snackBarError: state.getIn(["pageList", "snackBarError"]),
    page: state.getIn(["selectionPage", "page"]),
    lastPage: state.getIn(["selectionPage", "lastPage"]),
  }));

  // UseEffects used for filtering by price
  useEffect(() => {
    if (searchPriceMin || searchPriceMax
      || orderType) {
      setSearchKey("0###" + searchPriceMin + searchPriceMax + orderType);
    }
  }, [searchPriceMin, searchPriceMax
    , orderType
  ]);

  useEffect(() => {
    if (searchPage) {
      setSearchKey(searchPage + "###" + searchPriceMin + searchPriceMax + orderType);
    }
  }, [searchPage]);

  useEffect(() => {
    if (!searchKey) return;
    const searchPageToRequest = parseInt(searchKey.split("###")[0]);
    if ((searchPriceMin || searchPriceMax || orderType)) {
      dispatch(filterSelectionPageLaunch({
        orderType,
        searchPage: searchPageToRequest,
        searchLocale,
        searchPriceMin,
        searchPriceMax,
      }));
    }
  }, [searchKey]);

  const currentListMinSearchPrice = 0;
  const currentListMaxSearchPrice = 300;

  let zawemaSelection = data.flapSelection.edges.filter(({ node: productContent }) => {
    if (productContent.childContentfulFicheProduitProductsFieldJsonNode == null) return false;
    const products = productContent.childContentfulFicheProduitProductsFieldJsonNode.products || [];
    let availableProduct = false;
    products.map((product) => {
      (product.offers || []).forEach((offer) => {
        if (JSON.parse(offer.isAvailable)) {
          return (availableProduct = true)
        }
      });
      return null
    });
    return availableProduct
  })
    // send Products Info to ProductList
    .map(({ node: productContent }) => {
      const startDate = productContent.crossedPriceStartDate !== null && productContent.crossedPriceStartDate !== "null" ? new Date(productContent.crossedPriceStartDate) : null;
      const endDate = productContent.crossedPriceEndDate !== null && productContent.crossedPriceEndDate !== "null" ? new Date(productContent.crossedPriceEndDate) : null;
      const defaultProduct = productContent.childContentfulFicheProduitProductsFieldJsonNode.products.filter(product => product.offers.some(offer => JSON.parse(offer.isAvailable)))[0];
      const defaultImageId = defaultProduct.images[0];
      const defaultImage = ((data.productImages || {}).edges || [1]).filter(({ node: image }) => image.contentful_id === defaultImageId)[0]?.node || null;
      const defaultOffer = defaultProduct.offers[0];
      const defaultSeller = productContent.seller.corporateName || {};
      const defaultSellerId = productContent.cataloger || {};
      const urlProductPage = `/${pageContext.locale}/produits/${productContent.principalSveCategory.slug}/${productContent.slug}`
      let price = Number(defaultOffer.priceWithVat);
      let crossedPrice = Number(defaultOffer.crossedPriceWithVat);
      if ((startDate !== null || endDate !== null) && crossedPrice > 0 && crossedPrice > price && (today <= startDate || today > endDate)) {
        price = defaultOffer.crossedPriceWithVat;
        crossedPrice = null;
      }
      return {
        title: productContent.title,
        id: productContent.childContentfulFicheProduitProductsFieldJsonNode.products[0].id,
        image: defaultImage || null,
        price: price,
        priceDescription: defaultOffer.priceDescription,
        crossedPrice: crossedPrice,
        corporateName: defaultSeller,
        sellerId: defaultSellerId,
        urlProductPage: urlProductPage,
        flapSelectionZawema: JSON.parse(productContent.flapSelectionZawema),
        offerId: defaultOffer.id,
        goal: productContent.attributes.goal,
        porosity: productContent.attributes.porosity,
        careSteps: productContent.attributes.careSteps,
      }
    });
  // send products infos to ProductPage by algolia
  if (productContents) {
    zawemaSelection = productContents.map((hit) => {
      const urlProductPage = `/${pageContext.locale}/produits/${hit.categoryPath}/${hit.urlProductPage}`;
      const startDate = hit.crossedPriceStartDate !== null && hit.crossedPriceStartDate !== "null" ? new Date(hit.crossedPriceStartDate) : null;
      const endDate = hit.crossedPriceEndDate !== null && hit.crossedPriceEndDate !== "null" ? new Date(hit.crossedPriceEndDate) : null;
      let price = hit.price;
      let crossedPrice = hit.crossedPriceWithVat;
      if ((startDate !== null || endDate !== null) && crossedPrice > 0 && crossedPrice > price && (today <= startDate || today > endDate)) {
        price = hit.crossedPriceWithVat;
        crossedPrice = null;
      }
      return {
        title: hit.title,
        id: hit.productId,
        image: {
          description: hit.title,
          file: {
            url: hit.imageUrl
          }
        },
        price: price,
        priceDescription: hit.priceDescription,
        crossedPrice: crossedPrice,
        corporateName: hit.sellerName,
        sellerId: hit.sellerId,
        urlProductPage: urlProductPage,
        flapSelectionZawema: hit.flapSelectionZawema,
        offerId: hit.offerId,
        goal: hit.goal,
        porosity: hit.porosity,
        careSteps: hit.careSteps
      }
    });
  }

  const handleClick = (pageNumber) => {
    setSearchPage(pageNumber);
    if (pageNumber === 0) {
      setSearchKey("0###" + searchPriceMin + searchPriceMax + orderType);
    }
  }

  return (
    <Layout
      lang={pageContext.locale}
      firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
        return (
          sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
        )
      })}
      secondLevelCategories={data.menuSecondLevel.edges}
      thirdLevelCategories={data.menuThirdLevel.edges}
    >
      {/* SEO */}
      <Helmet>
        <html lang="fr" />
        <title>Sélection Zawema</title>
        {metas && metas.map(meta => (
          <meta key={meta.name} name={meta.name} content={meta.content} />
        ))}
      </Helmet>

      {/* Filtering section in mobile view */}
      <Transition
        show={isOpenFilter}
        as={Fragment}
        enter="transition-opacity duration-150 transform"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150 transform"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-full absolute top-0 bg-white z-50">
          <div className="w-full flex flex-col sticky bottom-0">
            <div className="w-full p-6 flex justify-end">
              <button
                className='bg-orange-dark'
                onClick={(e) => setIsOpenFilter(!isOpenFilter)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className={"flex flex-wrap"}
              style={classes.filteringSection}
            >
              <div className={"w-5/6 h-full pl-5 z-30 pb-40"}>
                <h3 className="font-medium text-semi">Trier par :</h3>
                <DropdownSelector
                  increasingPrice={() => { setOrderType("asc-price") }}
                  decreasingPrice={() => { setOrderType("desc-price") }}
                  decreasingWeight={() => { setOrderType("desc-weight") }}
                  orderType={orderType}
                />
              </div>
              {/* This filter uses Algolia */}
              <div className={"w-5/6 h-full mx-auto pb-14 z-0"}>
                <PriceFilter
                  initValues={[currentListMinSearchPrice, currentListMaxSearchPrice]}
                  onPriceChange={([min, max]) => {
                    setSearchPriceMin(min);
                    setSearchPriceMax(max);
                  }}
                  minimumPrice={searchPriceMin}
                  maxPrice={searchPriceMax}
                />
              </div>
            </div>
            <div className='w-full mb-10 flex justify-center'>
              <button
                className="w-1/2 bg-orange-dark shadow-lg py-2 font-bold text-base"
                onClick={() => setIsOpenFilter(!isOpenFilter)}
              >
                Valider
              </button>
            </div>
          </div>
        </div>

      </Transition>

      {!isOpenFilter
        && <>
          <NavBarMenu
            firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
              return (
                sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
              )
            })}
            secondLevelCategories={data.menuSecondLevel.edges}
            thirdLevelCategories={data.menuThirdLevel.edges}
            lang={pageContext.locale}
          />
          <CoverImage
            imageDesktop={selectionImage}
            imageMobile={selectionImage}
            title="La sélection Zawema"
            altDesktop="woman with curly hairs"
            altMobile="woman with curly hairs"
          />
          {/* Container for filtering and results */}
          <div className="flex pb-14">
            {/* Filtering section on left side's page */}
            <div className="w-full hidden md:flex flex-col md:w-3/12" style={classes.filtersContainer}>
              <div className={"flex flex-wrap pl-5"} style={classes.filteringSection}>
                <div className={"w-full h-full py-6 z-40 pb-14"}>
                  <h3 className="font-medium text-semi">Trier par :</h3>
                  <DropdownSelector
                    increasingPrice={() => { setOrderType("asc-price") }}
                    decreasingPrice={() => { setOrderType("desc-price") }}
                    decreasingWeight={() => { setOrderType("desc-weight") }}
                    orderType={orderType}
                  />
                </div>
                {/* This filter uses Algolia */}
                <div className={"w-5/6 h-full mx-auto md:mx-0 pb-14 z-0 "}>
                  <PriceFilter
                    initValues={[currentListMinSearchPrice, currentListMaxSearchPrice]}
                    onPriceChange={([min, max]) => {
                      setSearchPriceMin(min);
                      setSearchPriceMax(max);
                    }}
                    minimumPrice={searchPriceMin}
                    maxPrice={searchPriceMax}
                  />
                </div>
              </div>
            </div>

            {/* Results section with products cards on right side of the page */}
            <div className={"flex flex-col w-full md:w-9/12 mb-12"}>
              <div className={"pt-8 pl-2 2xl:pl-0 grid grid-cols-2 gap-x-4 gap-y-8 xl:grid-cols-3 2xl:grid-cols-4"}>
                {isLoading && (
                  <Loader />
                )}
                {!isLoading && zawemaSelection.map((product) => { return <ProductListCard product={product} /> })}
              </div>
              {!isLoading && zawemaSelection.length === 0 && (
                <div className="mt-4 text-center">
                  <p>Aucun résultat</p>
                </div>
              )}

              <Pagination
                page={page}
                handleClick={(page) => handleClick(page)}
                lastPage={lastPage}
              />
            </div>
            {/* Filter bottom in mobile version */}
            <div className='w-full flex justify-center fixed bottom-8'>
              <button
                className='md:hidden rounded-full bg-black text-white flex justify-center items-center py-2 px-4'
                onClick={() => setIsOpenFilter(!isOpenFilter)}
              >
                Filtres
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
          </div>

        </>
      }

      {!snackBarLoading && <SnackBar message={snackBarMessage} open={snackBarOpen} error={snackBarError} />}

    </Layout>
  );
};
export default SelectionPage;

export const query = graphql`

          query($locale: String!){
            menuFirstLevel: allContentfulCategorySve(filter: {slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}) {
            edges {
            node {
            slug
          categoryName
        }
      }
    }
          menuSecondLevel: allContentfulCategorySve(filter: {parentCategory: {slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}
          sort : {fields: [slug], order: ASC}) {
            edges {
            node {
            slug
          categoryName
          categoryEncartTitle
          parentCategory {
            slug
            categoryName
          categoryEncartTitle
          }
        }
      }
    }
          menuThirdLevel: allContentfulCategorySve(filter: {parentCategory: {parentCategory: {slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}}
          sort : {fields: [slug], order: ASC}) {
            edges {
            node {
            slug
          categoryName
          categoryEncartTitle
          parentCategory {
            slug
            categoryName
          categoryEncartTitle
          parentCategory {
            slug
              categoryName
          categoryEncartTitle
            }
          } 
        }
      }
    }
          flapSelection: allContentfulFicheProduit(filter: {flapSelectionZawema: {eq: true}, node_locale: {eq: $locale}}) {
            edges {
            node {
            seller {
            corporateName
          }
          partnerCategory {
            name
          }
          attributes {
            goal
                  porosity
          careSteps
              }
          cataloger
          poids
          seller {
            corporateName
          }
          title
          slug
          cataloger
          childContentfulFicheProduitProductsFieldJsonNode {
            products {
            gtin
            id
          images
          offers {
            crossedPriceEndDate
              crossedPriceStartDate
          crossedPriceWithVat
          id
          isAvailable
          leadtimeToShip
          priceWithVat
          sellerId
          sellerSku
            }
          }
      }
          cataloger
          flapSelectionZawema
          principalSveCategory {
            categoryName
        slug
      }
          secondarySveCategories {
            categoryName
        slug
      }
    }
          previous {
            slug
          }
  }
  }
          productImages: allContentfulAsset(filter: {node_locale: {eq: $locale}}) {
            edges {
            node {
            title
              file {
            url
          }
          description
          contentful_id
            }
          }
        }
        allContentfulPageMentionsLegales(filter: {node_locale: {eq: $locale}}) {
            edges {
              node {
                id
                text {
                  raw
                }
              }
            }
          }
        markdownRemark(fileAbsolutePath: {regex: "/selection.md/"}) {
          html
          frontmatter {
              title
              description
              ogtitle
              ogdescription
          }
        }
  }
          `
