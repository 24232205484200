const getStyles = (theme) => ({
  filteringSection: {
    flexFlow: "column"
  },
  filtersContainer: {
    borderRight: "1px solid #AFAFAF"
  }
});

export default getStyles;
